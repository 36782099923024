<template>
  <div class="login">
    <CustomInstallApp />
    <section>
      <div class="imgBx">
        <img src="img/login/fondo.jpg" alt="cosmetic" class="fondo-1" />
        <img src="img/login/fondo-2.png" alt="cosmetic" class="fondo-2" />
      </div>
      <div class="contentBx">
        <div class="formBx">
          <!-- <h2>Bienvenido</h2>
          <p class="welcome-message">
            Por favor, proporcione la credencial de inicio de sesión para
            continuar y tener acceso a todos Nuestros servicios
          </p> -->
          <div class="logo">
            <img src="img/login/logo.png" alt="logo" />
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="fieldBx">
              <v-text-field
                color="darkLight"
                v-model="email"
                :rules="emailRules"
                placeholder="Correo electrónico"
                required
                prepend-inner-icon="mdi-email"
                @keyup.enter="login()"
              ></v-text-field>
            </div>

            <div class="fieldBx">
              <v-text-field
                color="darkLight"
                v-model="password"
                prepend-inner-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[passwordRules.required, passwordRules.regex]"
                :type="show1 ? 'text' : 'password'"
                placeholder="Contraseña"
                hint="Al menos 6 carácteres"
                @click:append="show1 = !show1"
                @keyup.enter="login()"
              ></v-text-field>
            </div>

            <div class="inputBx">
              <v-btn
                color="darkLight"
                class="mr-4 btnLogin white--text"
                rounded
                large
                @click="login()"
                :loading="loader"
              >
                Entrar
              </v-btn>
            </div>
          </v-form>

          <div class="footerBx">
            <div class="content">
              <div class="images">
                <div class="center">
                  <img src="img/login/logo-1.png" />
                </div>

                <div class="center">
                  <img src="img/login/logo-2.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ConfigStorage from "../core/storage/config-storage.js";
import UserStorage from "../core/storage/user-storage.js";
import UserServices from "../core/services/user-services.js";
import useValidate from "../shared/validate/useValidate";
import CustomInstallApp from "../shared/components/custom-install-app/CustomInstallApp.vue";

export default {
  name: "Login",
  data: () => ({
    valid: false,
    loader: false,
    email: "",
    emailRules: [useValidate["required"], useValidate["email"]],
    password: "",
    passwordRules: {
      required: useValidate["required"],
      regex: useValidate["password"],
    },
    show1: false,
  }),
  components: {
    CustomInstallApp,
  },
  methods: {
    login() {
      const { login } = UserServices();
      const { addConfig } = ConfigStorage();
      const validate = this.$refs.form.validate();
      if (validate) {
        this.loader = true;
        const data = {
          email: this.email.toLowerCase().trim(),
          password: this.password,
        };

        login(data).then((resp) => {
          const response = resp.data;
          if (response.code == 200) {
            addConfig(response.data.token);
            this.getUserMe();
          } else if (response.code == 400) {
            this.$toast.error(response.data);
          } else if (resp.data.code == 404) {
            this.$toast.error(response.data);
          }
          this.loader = false;
        });
      }
    },
    getUserMe() {
      const { userMe } = UserServices();
      const { addUser } = UserStorage();
      userMe().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          addUser(response.data);
          this.$router.push("/");
        }
      });
    },
  },
};
</script>
