<template>
  <div>
    <div class="fondo-app" v-if="showButton"></div>

    <div class="alert-install" v-if="showButton">
      <div class="btn-close" @click="closeCard()">
        <v-icon>mdi-close</v-icon>
      </div>
      <div class="grid-center">
        <div class="grid">
          <div class="img">
            <img src="img/home/logo.png" />
          </div>
          <div class="content">
            <div>
              <div class="title">Gestion de Ventas JL</div>
              <div class="subtitle">cosmetics.grupojldevenezuela.com</div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-install" @click="addToHomeScreen()">
        <v-icon>mdi-plus</v-icon>
        Instalar aplicación
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomInstallApp",
  data: () => ({
    deferredPrompt: {},
    showButton: false,
  }),
  mounted() {
    this.install();
  },
  methods: {
    install() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        window.addEventListener("beforeinstallprompt", (e) => {
          // Prevent the mini-infobar from appearing on mobile
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          // Update UI notify the user they can install the PWA
          this.showButton = true;
        });
      }
    },
    addToHomeScreen() {
      if (this.deferredPrompt) {
        // Show the prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
            this.showButton = false;
          } else {
            console.log("User dismissed the A2HS prompt");
            this.showButton = false;
          }
          this.deferredPrompt = null;
        });
      }
    },
    closeCard() {
      this.showButton = false;
    },
  },
};
</script>

<style lang="scss">
.fondo-app {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
}

.alert-install {
  background: #fff;
  position: absolute;
  z-index: 11;
  width: 100%;

  .grid-center {
    padding: 15px 20px;
  }

  .alert-title {
    color: #444;
    font-size: 18px;
  }

  .alert-message {
    padding-bottom: 5px;
  }

  .grid {
    display: grid;
    grid-template-areas: "img content";
    grid-template-columns: 100px auto;

    .img {
      grid-area: img;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 55px;
      }
    }

    .content {
      grid-area: content;
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: #444;
      }

      .subtitle {
        font-size: 13px;
        color: rgb(68 68 68 / 57%);
      }
    }
  }

  .btn-install {
    text-align: center;
    padding: 10px;
    background: var(--color-primary);
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    i {
      font-size: 20px;
      margin-right: 5px;
      color: #fff;
    }
  }

  .btn-close {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 25px;
    z-index: 20;
    color: #444;
  }
}
</style>
